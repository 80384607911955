import axios from "./interceptors";
import {
  PORT_API_LOGIN,
  PORT_API_PASSWORD_RESET_EMAIL,
  PORT_API_PASSWORD_RESET_MODIFY,
  PORT_API_ACTIVITY_CREATE,
  USERS_API_URL,
  GS_WEB_API_URL
} from "../config/environment";
import { getLanguage } from "../utils/utils";

export const authApi = (credentials) => {
  const data = {
    LangCode: getLanguage(),
    Username: credentials.username,
    Password: credentials.password,
  };
  return axios({
    method: "POST",
    url: `${PORT_API_LOGIN}/api/login/client`,
    data: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  });
};

export const loginSso = (idToken) =>{
  let data = {
      Token: idToken,
      Domain: "students"        
  };
  return axios({
      method: "POST",
      url: `${USERS_API_URL}/api/login/sso`,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify(data)
  });
};

export const passwordResetSendEmail = (email) => {
  const data = {
    LangCode: getLanguage(),
    Email: email,
  };
  return axios({
    method: "POST",
    url: `${PORT_API_PASSWORD_RESET_EMAIL}/api/accounts/password/reset/petition`,
    data: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  });
};

export const modifyPassword = (token, password, confirmPassword) => {
  const data = {
    Token: token,
    Password: password,
    PasswordRetype: confirmPassword,
  };
  return axios({
    method: "POST",
    url: `${PORT_API_PASSWORD_RESET_MODIFY}/api/accounts/password/reset/modify`,
    data: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  });
};

export const activity = () => {
  return axios.post(`${PORT_API_ACTIVITY_CREATE}/api/activity/create`);
};

export const getSettingsPowerDe = () => {
  return axios.get(`${GS_WEB_API_URL}/api/platform/settings`);
};
