import React, { useState, useEffect } from "react";
import "./style.css";
import Menu from "../menu";
import Homeworks from "./homeworks";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import CompletedAssignments from "./completed-assignments";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Task = () => {
  const { t } = useTranslation(["task"]);
  let query = useQuery();
  const history = useHistory();
  let location = useLocation();
  const [tab, setTab] = useState(query.get("q") || "homework");

  function handleChange(e) {
    const value = e.target.value;
    setTab(value);
    if (query.get("q")) {
      history.push(location.pathname);
    }
  }

  return (
    <>
      <Menu />
      <div className="wrapper-content">
        <div className="center-container">
          <div className="wrapper-message-tabs content-max-width">
            <div className="message-tabs">
              <input
                id="homework"
                type="radio"
                name="tabs"
                value="homework"
                onChange={handleChange}
                checked={tab === "homework"}
              />
              <label htmlFor="homework" onChange={handleChange}>
                {t("homeworks")}
              </label>
              <div className="message-tabs--content">
                {tab === "homework" && <Homeworks />}
              </div>

              <input
                id="completed"
                type="radio"
                name="tabs"
                value="completed"
                onChange={handleChange}
                checked={tab === "completed"}
              />
              <label
                className="tab-homework"
                htmlFor="completed"
                onChange={handleChange}
              >
                {t("completedAssignments")}
              </label>
              <div className="message-tabs--content">
                {tab === "completed" && <CompletedAssignments />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Task;
