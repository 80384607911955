import React, { useState, useEffect } from "react";
import Menu from "../../menu";
import Epub from "../epub";
import Pdf from "../pdf";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getMaterialAdditionalById } from "../../../../api/lesson";
import { Loader } from "../../../common/styled-components/loader";
import { getFileExtension } from "../../../../utils/file";

const BookDetail = () => {
  const { t } = useTranslation(["library"]);
  const { bookId } = useParams();
  const history = useHistory();
  const [material, setMaterial] = useState({});
  const [loading, setLoading] = useState(false);
  const [ext, setExt] = useState("");

  useEffect(() => {
    getBookById();
  }, []);

  const getBookById = async () => {
    setLoading(true);
    try {
      const res = await getMaterialAdditionalById(bookId);
      const data = res.data;
      const sp = data.Url.split("/");
      const file = sp[sp.length - 1];
      const ext = getFileExtension(file);
      setExt(ext);
      setMaterial(data);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  return (
    <>
      <Menu />
      <div className="wrapper-content">
        <div className="center-container">
          <div>
            <button className="btn-back" onClick={() => history.push("/students/library")}>
              <i className="fas fa-arrow-circle-left"></i> {t("goToLibrary")}
            </button>
          </div>
          {material.ForDownload === "0" && ext === "epub" && (
            <Epub book={material} />
          )}
          {material.ForDownload === "0" && ext === "pdf" && (
            <Pdf book={material} />
          )}
          {loading && <Loader />}
        </div>
      </div>
    </>
  );
};

export default BookDetail;
