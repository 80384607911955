import React, { useEffect, useState } from "react";
import "./style.css";
import { getMessageDetail } from "../../../../api/message";
import { useTranslation } from "react-i18next";
import { getTimeZone } from "../../../../utils/utils";
import moment from "moment";
import "moment-timezone";

const ImagePreview = ({ item }) => {
  const file = getFileExtension(item.AttachmentName);
  const imageLoad = require(`../../../../assets/images/${assignImage(file)}`);

  function assignImage(ext) {
    switch (ext) {
      case "csv":
        return "icon-file-csv.jpg";
      case "doc":
        return "icon-file-doc.jpg";
      case "docx":
        return "icon-file-doc.jpg";
      case "jpg":
        return "icon-file-jpg.jpg";
      case "jpge":
        return "icon-file-jpg.jpg";
      case "png":
        return "icon-file-jpg.jpg";
      case "mp3":
        return "icon-file-mp3.jpg";
      case "mp4":
        return "icon-file-mp3.jpg";
      case "pdf":
        return "icon-file-pdf.jpg";
      case "xlm":
        return "icon-file-xlm.jpg";
      case "xlsx":
        return "icon-file-xlm.jpg";
      case "xlsm":
        return "icon-file-xlm.jpg";
      default:
        return "icon-file-jpg.jpg";
    }
  }

  function getFileExtension(filename) {
    return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined;
  }

  return (
    <>
      <div className="preview-docs-message--seven-col">
        {file === "jpg" || file === "png" ? (
          <img src={item.AttachmentUrl} alt="" />
        ) : (
          <img src={imageLoad} alt="" />
        )}
        <p>
          <a href={item.AttachmentUrl} target="_blank" download>
            {item.AttachmentName}
          </a>
        </p>
      </div>
    </>
  );
};

const MessageContent = ({ metadataMessage }) => {
  const { t, i18n } = useTranslation(["message"]);
  const [data, setData] = useState(undefined);
  const [recipients, setRecipients] = useState("");

  useEffect(() => {
    if (
      metadataMessage.EmailRecipientModelList &&
      metadataMessage.EmailRecipientModelList.length
    ) {
      const arr = metadataMessage.EmailRecipientModelList.map(
        (item) => item.RecipientName
      );
      setRecipients(arr.toString());
    }
  }, [metadataMessage]);

  const hadleShowMore = async () => {
    const parentId = metadataMessage.EmailMessageModel.ParentMessageId;
    const res = await getMessageDetail(parentId);
    const data = res.data.EmailMessageFullDetailModel;
    setData(data);
  };

  return (
    <>
      <h1 className="title-modal-message">
        {metadataMessage.EmailMessageModel &&
          metadataMessage.EmailMessageModel.Subject}
      </h1>

      {metadataMessage.EmailMessageModel && (
        <div className="modal-message-body">
          {!recipients && (
            <div className="modal-message-body--col-name">
              {metadataMessage.EmailMessageModel.UserFullName}
            </div>
          )}
          {recipients && (
            <div className="modal-message-body--col-name">
              {t("to")}: {recipients}
            </div>
          )}
          <div className="modal-message-body--col-date">
            {metadataMessage.EmailMessageModel.DateCreated ? moment.utc(metadataMessage.EmailMessageModel.DateCreated, "MM/DD/YYYY HH:mm:ss")
              .tz(getTimeZone())
              .format("MM/DD/YYYY hh:mm a") : ""}
          </div>
          <div className="modal-message-body--col-message">
            {metadataMessage.EmailMessageModel.Body}
          </div>
          <div className="preview-docs-message">
            {metadataMessage.EmailAttachmentModelList &&
              metadataMessage.EmailAttachmentModelList.length !== 0 &&
              metadataMessage.EmailAttachmentModelList.map((item, i) => (
                <ImagePreview key={i} item={item} />
              ))}
          </div>
          {metadataMessage.EmailMessageModel.ParentMessageId !== "0" && (
            <a className="button-more-answers" onClick={hadleShowMore}>
              <i className="fas fa-ellipsis-h"></i> {t("seeMore")}
            </a>
          )}
        </div>
      )}
      {data && <MessageContent metadataMessage={data} />}
    </>
  );
};

export default MessageContent;
