import React, { useState } from "react";
import { fileUpload } from "../../../../api/homework";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";

const DropzoneQuiz = ({ code }) => {
  const { t, i18n } = useTranslation(["homework"]);
  const [filesSelecteds, setFilesSelecteds] = useState([]);

  const onDrop = async (files) => {
    const file = files[0];
    const res = await fileUpload(file, code);
    setFilesSelecteds([
      ...filesSelecteds,
      { AttachmentName: file.name, AttachmentURL: res.data.Messages[0] },
    ]);
  };

  return (
    <div className="main-dropzone">
      <div className="content-dropzone">
        <Dropzone onDrop={onDrop}>
          {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <i className="fas fa-paperclip"></i>
              {!isDragActive && t("attachFile")}
              {isDragActive && !isDragReject && "Drop it like it's hot!"}
              {isDragReject && "No accept!"}
            </div>
          )}
        </Dropzone>
      </div>
      <div>
        {filesSelecteds.length !== 0 &&
          filesSelecteds.map((item, i) => (
            <div key={i} className="fileSelected-caption">
              {item.AttachmentName}
            </div>
          ))}
      </div>
    </div>
  );
};

export default DropzoneQuiz;
