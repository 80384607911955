import React, { useState, useEffect } from "react";
import "./style.css";
import { SkeletonStyled } from "../../../common/styled-components/skeleton";
import styled from "styled-components";
import Pagination from "@material-ui/lab/Pagination";
import MessageDetailDialog from "../message-detail-dialog";
import {
  getMetadataReceivedsThunk,
  getMetadataReceivedsSuccess,
} from "../../../../redux/actions/received";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getTimeZone } from "../../../../utils/utils";
import moment from "moment";
import "moment-timezone";

const EmptyListStyled = styled.p`
  color: #ccc;
  display: block
  justify-content: center;
  text-align: center;
  margin: 3em auto;

  & i {
    display: block;
    margin: 2em 0 0.25em 0;
    font-size: 45px;
    opacity: .5;
  }
`;

const Received = ({ setPaginationReceived }) => {
  const { t } = useTranslation(["message"]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [open, setOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState(null);

  const dispatch = useDispatch();
  const { metadataReceiveds, loading } = useSelector((state) => ({
    metadataReceiveds: state.metadataReceiveds,
    loading: state.loading,
  }));

  useEffect(() => {
    dispatch(getMetadataReceivedsSuccess({}));
    dispatch(getMetadataReceivedsThunk(page, pageSize));
  }, []);

  useEffect(() => {
    setPaginationReceived({ page, pageSize });
  }, [page]);

  const getMetadataReceiveds = () => {
    dispatch(getMetadataReceivedsThunk(page, pageSize));
  };

  const handleChange = (event, value) => {
    setPage(value);
    dispatch(
      getMetadataReceivedsSuccess({
        ...metadataReceiveds,
        EmailMessageModelList: [],
      })
    );
    dispatch(getMetadataReceivedsThunk(value, pageSize));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleItemSelected = (item) => {
    setItemSelected(item);
    handleClickOpen();
  };

  return (
    <>
      <div className="wrapper-table-homework">
        <table className="table-message">
          <tbody>
            {metadataReceiveds.EmailMessageModelList &&
              metadataReceiveds.EmailMessageModelList.map((item, i) => (
                <tr
                  key={i}
                  style={!item.Read ? { backgroundColor: "#eaf0f5" } : {}}
                >
                  <td
                    className="message-name"
                    onClick={() => handleItemSelected(item)}
                  >
                    {item.UserFullName}
                  </td>
                  <td
                    className="td-truncate"
                    onClick={() => handleItemSelected(item)}
                  >
                    {item.Subject}
                  </td>
                  <td
                    className="message-date"
                    onClick={() => handleItemSelected(item)}
                  >
                    <span className="clear">{item.DateCreated ? moment.utc(item.DateCreated, "MM/DD/YYYY HH:mm:ss").tz(getTimeZone()).format("MM/DD/YYYY hh:mm a") : ""}</span>
                  </td>
                  <td>
                    <button
                      type="submit"
                      className="button-message-tabs"
                      onClick={() => handleItemSelected(item)}
                    >
                      {!item.Read ? (
                        <i className="fas fa-envelope"></i>
                      ) : (
                        <i className="fas fa-envelope-open-text"></i>
                      )}
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {loading && (
          <SkeletonStyled>
            <div className="area"></div>
            <div className="area"></div>
            <div className="area"></div>
            <div className="area"></div>
          </SkeletonStyled>
        )}
        {!loading &&
          metadataReceiveds.EmailMessageModelList &&
          !metadataReceiveds.EmailMessageModelList.length && (
            <EmptyListStyled>
              <i className="fas fa-envelope-open"></i> {t("emptyReceived")}
            </EmptyListStyled>
          )}
        {metadataReceiveds.TotalPages > 1 && (
          <Pagination
            count={metadataReceiveds.TotalPages}
            page={page}
            onChange={handleChange}
          />
        )}
        {open && (
          <MessageDetailDialog
            open={open}
            onClose={handleClose}
            messageId={itemSelected.Id}
            read={itemSelected.Read}
            getMetadataReceiveds={getMetadataReceiveds}
          />
        )}
      </div>
    </>
  );
};

export default Received;
