import React from "react";
import "./style.css";
import LessonMarkIcon from "../../../../assets/images/lesson-mark.svg";
import LessonMenuChallege from "../../../../assets/images/LessonMenu-icon-challenge.svg";
import LessonMenuBack from "../../../../assets/images/LessonMenu-icon-back.svg";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import prevPath from "../../../../redux/actions/path";

const HomeworkMenu = () => {
  const { t, i18n } = useTranslation(["homework"]);
  const history = useHistory();
  const dispatch = useDispatch();

  const path = useSelector((state) => state.prevPath);

  const goBack = () => {
    if (path && path.indexOf("/students/task") !== -1) {
      history.push("/students/task?q=homework");
      dispatch(prevPath(null));
    } else {
      history.goBack();
    }
  };

  return (
    <>
      <div className="wrapper-menu">
        <ul className="navigation-menu">
          <li className="active">
            <a>
              {" "}
              <img src={LessonMenuChallege} />
              <span className="navigation-menu--item"> {t("exam")} </span>
            </a>
            <span className="lesson-mark">
              <img src={LessonMarkIcon} />
            </span>
          </li>
          <li className="highlight-item">
            <a onClick={goBack}>
              {" "}
              <img src={LessonMenuBack} />
              <span className="navigation-menu--item"> {t("back")} </span>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default HomeworkMenu;
