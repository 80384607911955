import React, { useState, useEffect } from "react";
import "./style.css";
import { getLessonAssignedByTeacher } from "../../../../api/lesson";
import { useSelector } from "react-redux";
import { SkeletonStyled } from "../../../common/styled-components/skeleton";
import IconCongratsStar from "../../../../assets/images/icon-congratulations-homework.png";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Pagination from "@material-ui/lab/Pagination";
import { useTranslation } from "react-i18next";
import { assignColor } from "../../../../utils/lesson";
import { getNameSubject } from "../../../../utils/subject";
import { getTimeZone } from "../../../../utils/utils";
import moment from "moment";
import "moment-timezone";

const MessageCongratsHomework = styled.div`
  color: #222;
  display: block;
  justify-content: center;
  text-align: center;
  margin: 3em auto;
  font-size: 13px;

  & img {
    display: block;
    margin: 2em auto 0.25em auto;
  }

  & h1 {
    font-size: 30px;
    line-height: 0;
    color: #AB863D;
  }
`;

const AssignedLessons = () => {
  const { t, i18n } = useTranslation(["task"]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [lessons, setLessons] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const { subjects } = useSelector((state) => ({
    subjects: state.subjects,
  }));

  useEffect(() => {
    getLessonAssignedByTeacherPaginate(page, pageSize);
  }, []);

  const getLessonAssignedByTeacherPaginate = async (pag, size) => {
    setLoading(true);
    const res = await getLessonAssignedByTeacher(pag, size);
    const data = res.data.PendingLessonsModelList
      ? res.data.PendingLessonsModelList
      : [];
    setLessons(data);
    setLoading(false);
    setCount(res.data.TotalPages);
  };

  const handleChange = (event, value) => {
    setPage(value);
    setLessons([]);
    getLessonAssignedByTeacherPaginate(value, pageSize);
  };

  return (
    <>
      <div className="wrapper-table-homework">
        <table className="content-table-homework">
          <thead>
            <tr>
              <th>{t("assignedBy")}</th>
              <th>{t("assignedLesson")}</th>
              <th>{t("dueDate")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {lessons.map((lesson, i) => (
              <tr key={i}>
                <td data-column="Asignado por:">
                  <span className="table-homework--name-matter">
                    {" "}
                    {lesson.SentBy}{" "}
                  </span>
                  <p
                    className="table-homework--txt-matter"
                    style={{ color: `${assignColor(lesson.Subject)}` }}
                  >
                    {getNameSubject(lesson.Subject, subjects)}
                  </p>
                </td>
                <td data-column="Lección Asignada:">
                  <span className="table-homework--title">{lesson.Title}</span>
                </td>
                <td data-column="Fecha de Entrega:">
                  <span className="table-homework--date">
                    {lesson.DueDate ? moment.utc(lesson.DueDate, "MM/DD/YYYY HH:mm:ss")
                      .tz(getTimeZone())
                      .format("MM/DD/YYYY hh:mm a") : ""}
                  </span>
                </td>
                <td data-column="">
                  <button
                    type="submit"
                    className="button-table-homework"
                    onClick={() =>
                      history.push(`/students/lesson/${lesson.LessonId}`)
                    }
                  >
                    {" "}
                    {t("viewLesson")}{" "}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {loading && (
          <SkeletonStyled>
            <div className="area"></div>
            <div className="area"></div>
            <div className="area"></div>
            <div className="area"></div>
          </SkeletonStyled>
        )}
        {!loading && !lessons.length && (
          <MessageCongratsHomework>
            <img src={IconCongratsStar} alt="" />{" "}
            <h1>{t("congratulations")}</h1> {t("emptyTask")}
          </MessageCongratsHomework>
        )}
        {count > 1 && (
          <Pagination count={count} page={page} onChange={handleChange} />
        )}
      </div>
    </>
  );
};

export default AssignedLessons;
