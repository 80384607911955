import { getMessagesUnreads } from "../../api/message";

export const getMessagesUnreadsSuccess = (total) => {
  return {
    type: "UNREAD_MESSAGES",
    payload: total,
  };
};

export const getMessagesUnreadsThunk = () => {
  return async (dispatch, getState) => {
    try {
      const res = await getMessagesUnreads();
      const total = res.data.TotalUnread;
      dispatch(getMessagesUnreadsSuccess(total));
    } catch {}
  };
};
