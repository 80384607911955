import React from "react";
import "./style.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { getMessageDetail } from "../../../../api/message";
import MessageContent from "../message-content";
import { useTranslation } from "react-i18next";

const MessageDetailResend = ({ onClose, open, messageId }) => {
  const { t, i18n } = useTranslation(["message"]);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [metadataMessage, setMetadataMessage] = React.useState({});

  React.useEffect(() => {
    (async () => {
      const res = await getMessageDetail(messageId);
      const data = res.data.EmailMessageFullDetailModel;
      setMetadataMessage(data);
    })();
  }, []);

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogContent>
          <div className="modal-text-right">
            <button className="close-windows" onClick={handleClose}>
              <i className="fas fa-times"></i>
            </button>
          </div>

          <MessageContent metadataMessage={metadataMessage} />

          <div className="modal-footer">
            <button
              type="submit"
              className="button-modal-message-secondary"
              onClick={handleClose}
            >
              {" "}
              {t("cancel")}{" "}
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MessageDetailResend;
