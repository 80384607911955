import React from "react";
import "./style.css";
import AvatarIcon from "../../../assets/images/sample-avatar.png";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout } from "../../../redux/actions/user";
import { useTranslation } from "react-i18next";
import { hasRole, getUser } from "../../../utils/utils";
import { ROLE_STUDENT_STR } from "../../../config/environment";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { getSettings } from "../../../redux/actions/user";

const DropdownUser = ({ setIsComponentVisible, user }) => {
  const { parameters } = useSelector((state) => ({
    parameters: state.parameters,
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation(["home"]);
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  const userCurrent = getUser();

  const onLogout = async () => {
    if (isAuthenticated) {
      await instance.logoutPopup({
        postLogoutRedirectUri: "/#/login",
        mainWindowRedirectUri: "/#/login",
      });
    }
    setIsComponentVisible(false);
    dispatch(logout());
    dispatch(getSettings());
  };

  const goToHome = () => {
    history.push("/students/home");
    setIsComponentVisible(false);
  };

  const goToMessage = () => {
    history.push("/students/message");
    setIsComponentVisible(false);
  };

  return (
    <>
      <ul className="user-comp_menu">
        <div className="user-comp_info-user">
          <div className="double-border">
            <img src={AvatarIcon} alt="" />
          </div>
          <h1>{user.Name}</h1>
          <h4>
            {t("code")}: {user.InvitationCode}
          </h4>
        </div>

        <ul className="user-comp_submenu">
          {hasRole(userCurrent, [ROLE_STUDENT_STR]) && <li>
            <a onClick={goToHome}>
              <i className="fas fa-home"></i> {t("home")}{" "}
            </a>
          </li>}

          {hasRole(userCurrent, [ROLE_STUDENT_STR]) && parameters.messageManagementStudent && <li>
            <a onClick={goToMessage}>
              <i className="fas fa-envelope"></i> {t("message")}{" "}
            </a>
          </li>}

          <li>
            <a onClick={onLogout}>
              <i className="fas fa-sign-out-alt"></i> {t("logout")}{" "}
            </a>
          </li>
        </ul>
      </ul>
    </>
  );
};

export default DropdownUser;
