import React from 'react'
import useDisableScroll from "../../../../hooks/useDisableScroll";
import { getDate } from "../../../../utils/utils";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CalendarDetailDialog = ({ isVisible, event, positionDialog, onCloseModal }) => {
    const { t } = useTranslation(["teams"]);
    // disable scroll when modal is open
    useDisableScroll(isVisible);
    const history = useHistory();

    if (!isVisible) return null

    // open event.joinUrl on new tab
    const handleJoinMeeting = (e) => {
        e.preventDefault()
        window.open(event.joinUrl, '_blank')
    }

    return (
        <>
            <div className="modal-overlay" onClick={onCloseModal}>
            </div>

            <div className="modalContent-MeetDetail ForceWitdht-OnlyTest dialog-teams" style={positionDialog}>
                <div className="meetDetail-content">
                    <div className="meetDetail-content--title">{event.title}</div>
                    <p className="meetDetail-content--date">{getDate(event.start, event.end)}</p>
                </div>
                <div>
                    <button className="buttonTeams-Primary mr-2" onClick={handleJoinMeeting}>
                        {t("join")}
                    </button>
                </div>

                <div className="meetDetail-aditionalInfo">
                    <ul className="aditionalInfo-Organizer">
                        <li>{event.organizer}</li>
                        <li> {" "}
                            {t("organizer")}
                        </li>
                    </ul>

                    {Boolean(event.attendees.length) ?
                        <ul className="aditionalInfo-Guest">

                            <li className="aditionalInfo-Guest--title">
                                {t("guests")}
                            </li>
                            {event.attendees.map((attendee, index) => (
                                <li key={index}>{attendee.emailAddress.name}</li>
                            ))}
                        </ul>
                        : null
                    }

                </div>
            </div>
        </>
    )
}


export default CalendarDetailDialog
