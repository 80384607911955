import React from "react";
import { fileUpload } from "../../../../api/message";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";

const DropzoneMessage = ({ setFilesSource }) => {
  const { t, i18n } = useTranslation(["message"]);
  const [filesSelecteds, setFilesSelecteds] = React.useState([]);

  const onDrop = async (files) => {
    const file = files[0];
    const res = await fileUpload(file);
    const attachment = { AttachmentName: file.name, AttachmentURL: res.data.Messages[1] };
    setFilesSelecteds([
      ...filesSelecteds,
      attachment
    ]);
    setFilesSource([
      ...filesSelecteds,
      attachment
    ]);
  };

  const removeItem = (index) => {
    const items = [...filesSelecteds];
    items.splice(index, 1);
    setFilesSelecteds(items);
    setFilesSource(items);
  }

  return (
    <div className="main-dropzone">
      <div className="content-dropzone">
        <Dropzone onDrop={onDrop}>
          {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <i className="fas fa-paperclip"></i>
              {!isDragActive && t("attachFile")}
              {isDragActive && !isDragReject && "Drop it like it's hot!"}
              {isDragReject && "No accept!"}
            </div>
          )}
        </Dropzone>
      </div>
      <div>
        {filesSelecteds.length !== 0 &&
          filesSelecteds.map((item, i) => (
            <div key={i} className="fileSelected-caption files-attached-list">
              <i className="fas fa-times" onClick={removeItem}></i>{item.AttachmentName}
            </div>
          ))}
      </div>
    </div>
  );
};

export default DropzoneMessage;
