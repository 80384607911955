import React from "react";
import "./style.css";
import HeaderLogin from "../../login/header";
import FooterLogin from "../../login/footer";
import { useLocation, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { modifyPassword } from "../../../api/user";
import styled from "styled-components";
import { toastr } from "react-redux-toastr";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const TextDangerStyled = styled.p`
  color: #e6e134;
  font-size: 11px;
  margin: auto;
  padding: 0.25em;
  text-align: right;
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ModifyPassword = () => {
  const history = useHistory();
  let query = useQuery();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(["change-pass"]);
  const { register, errors, handleSubmit } = useForm();
  const token = query.get("token") || "";

  const onSubmit = async (data) => {
    try {
      const res = await modifyPassword(
        token,
        data.password,
        data.confirmPassword
      );
      toastr.success(t("title"), t("changePassSuccess"));
    } catch {}
  };

  return (
    <>
      <HeaderLogin />
      <div className="wrapper-container-login">
        <div className="container-forgot-password">
          <form onSubmit={handleSubmit(onSubmit)}>
            <p className="icon-pass">
              <i className="fas fa-lock"></i>
            </p>

            <h1 className="container-login_title"> {t("title")} </h1>
            <p className="text-login">{t("message")}</p>
            <input
              type="password"
              name="password"
              placeholder={t("inputPassword")}
              ref={register({
                required: {
                  value: true,
                  message: t("validPassReq"),
                },
              })}
            />
            {errors.password && (
              <TextDangerStyled>{errors.password.message}</TextDangerStyled>
            )}
            <input
              type="password"
              name="confirmPassword"
              placeholder={t("inputRepeatPassword")}
              ref={register({
                required: {
                  value: true,
                  message: t("validPassReq"),
                },
              })}
            />
            {errors.confirmPassword && (
              <TextDangerStyled>
                {errors.confirmPassword.message}
              </TextDangerStyled>
            )}
            <button type="submit" className="container-login_btn-login">
              {" "}
              {t("submit")}{" "}
            </button>

            <button
              type="submit"
              className="container-login_btn-login-secondary"
              onClick={() => history.push("/login")}
            >
              {" "}
              {t("gotoLogin")}{" "}
            </button>
          </form>
        </div>
      </div>
      <FooterLogin />
    </>
  );
};

export default ModifyPassword;
