import React from "react";
import "./style.css";
import styled from "styled-components";
import { assignColor } from "../../../../../utils/lesson";

const LessonTitleStyled = styled.h4`
  background-color: #e8eff3;
  font-size: 16px;
  padding: 0.7em;
  color: #253084;
`;

const LessonTitle = ({ subject, lesson }) => {
  const imageLoad = require(`../../../../../assets/images/${assignIcon(
    subject.Subject
  )}`);

  function assignIcon(subject) {
    switch (subject) {
      case "EN":
        return "LessonEnglish-icon.svg";
      case "SP":
        return "LessonSpanish-icon.svg";
      case "MAT-EN":
        return "LessonMath-icon.svg";
      case "SCI-EN":
        return "LessonScience-icon.svg";
      case "MAT-SP":
        return "LessonMath-icon.svg";
      case "SCI-SP":
        return "LessonScience-icon.svg";
      case "SCI-SO":
        return "LessonSocialStudies-icon.svg";
      case "EDF-SP":
        return "LessonEducacionFisica-icon.svg";
      case "ART-SP":
        return "LessonBellasArtes-icon.svg";
      case "SLD-SP":
        return "LessonSalud-icon.svg";
      default:
        return "LessonDefault-icon.svg";
    }
  }

  return (
    <LessonTitleStyled>
      <div className="lesson-title-subject">
        <div className="lesson-title-subject__left">
          <img src={imageLoad} />
        </div>

        <div className="lesson-title-subject__right">
          <span
            className="lesson-title-subject__subject"
            style={{ color: `${assignColor(subject.Subject)}` }}
          >
            {subject.SubjectName}{" "}{subject.Level}
          </span>{" "}
          <span className="lesson-title-subject__lesson">
            {" "}
            {lesson.LessonTitle}{" "}
          </span>
        </div>
      </div>
    </LessonTitleStyled>
  );
};

export default LessonTitle;
