import React from "react";
import Menu from "../menu";
import styled from "styled-components";
import { GS_WEB_APP_INTERACTIVE } from "../../../config/environment";

const ContainerIframe = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 63.5%;

  @media (max-width: 450px) {
    padding-top: 100%;
  }
`;

const IFrameStyled = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

const GSInteractive = () => {
  return (
    <>
      <Menu />
      <div className="wrapper-content">
        <div className="center-container">
          <ContainerIframe>
            <IFrameStyled src={GS_WEB_APP_INTERACTIVE} />
          </ContainerIframe>
        </div>
      </div>
    </>
  );
};

export default GSInteractive;
