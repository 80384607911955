import React, { useState, useEffect } from "react";
import "./style.css";
import Menu from "../menu";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import Pagination from "@material-ui/lab/Pagination";
import styled from "styled-components";
import { Loader } from "../../common/styled-components/loader";
import { useSelector, useDispatch } from "react-redux";
import { getSubjectsAndCoursesThuk } from "../../../redux/actions/subjects";
import { currentGrade } from "../../../redux/actions/grade";
import { getLessonsSuccess } from "../../../redux/actions/lessons";
import { getLanguage } from "../../../utils/utils";
import { getGradesOrdinals } from "../../../utils/lesson";
import { getNameSubject } from "../../../utils/subject";
import { getForumsByLevelSubject } from "../../../api/forum";
import { useHistory } from "react-router-dom";

const EmptyListStyled = styled.p`
  color: #ccc;
  display: block
  justify-content: center;
  text-align: center;
  margin: 3em auto;

  & img {
    display: block;
    margin: 1em auto 0.25em auto;
    font-size: 45px;
    width: 175px;
    opacity: .5;
  }

  & i {
    display: block;
    margin: 1em auto 0.25em auto;
    font-size: 45px;
    width: 175px;
    opacity: .5;
  }
`;

const SelectGrade = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px solid #E9E9E9",
    color: state.isSelected ? "#FFF" : "#6F6F6F",
    padding: 10,
    fontSize: 12,
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const Forum = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(["forum"]);
  const { grade, subjects } = useSelector((state) => ({
    grade: state.grade,
    subjects: state.subjects,
  }));
  const [gradeSelected, setGradeSelected] = useState(null);
  const [grades, setGrades] = useState([]);
  const [matterSelected, setMatterSelected] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [count, setCount] = useState(0);
  const [forums, setForums] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getSubjectsAndCoursesThuk());
    getForums(grade, undefined, page, pageSize);
  }, []);

  useEffect(() => {
    const grades = Array.from(
      new Set(subjects.map((subject) => subject.Level))
    ).sort((a, b) => {
      return a - b;
    });
    const lists = grades.map((grade) => ({
      value: grade,
      label: getGradesOrdinals(grade, getLanguage()),
    }));
    setGrades(lists);
    setGradeSelected(
      grade
        ? { value: grade, label: getGradesOrdinals(grade, getLanguage()) }
        : {
            value: grades[0],
            label: getGradesOrdinals(grades[0], getLanguage()),
          }
    );
    if (!grade) {
      dispatch(currentGrade(grades.length ? grades[0] : null));
    }
  }, [subjects]);

  const getForums = async (grade, subject, page, pageSize) => {
    page = page - 1;
    const req = {
      SubjectCode: subject,
      LevelCode: grade,
      FromPage: page,
      Limiter: pageSize,
    };
    setLoading(true);
    try {
      const res = await getForumsByLevelSubject(req);
      const { ForumModelList, TotalPages } = res.data;
      setForums(ForumModelList);
      setCount(TotalPages);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const handleChangeGrade = (selected) => {
    setPage(1);
    setGradeSelected(selected);
    dispatch(currentGrade(selected.value));
    dispatch(getLessonsSuccess([]));
    getForums(selected.value, undefined, 1, pageSize);
    setMatterSelected(null);
  };

  const handleChangeSubject = (selected) => {
    setPage(1);
    setMatterSelected(selected);
    getForums(grade, selected.value, 1, pageSize);
  };

  const handleChangePaginate = (event, value) => {
    setPage(value);
    getForums(
      grade,
      matterSelected ? matterSelected.value : undefined,
      value,
      pageSize
    );
  };

  return (
    <>
      <Menu />
      <div className="wrapper-content">
        <div className="center-container">
          <div className="wrapper-forum">
            <h2>{t("forum")}</h2>
            <div className="content-forum">
              <div className="wrapper-col">
                <div className="two-col">
                  <div className="general-module">
                    <div className="selectTrelloGrade">
                      <Select
                        styles={SelectGrade}
                        value={gradeSelected}
                        onChange={handleChangeGrade}
                        options={grades}
                        placeholder="Select Grade"
                      />
                    </div>
                  </div>
                </div>
                <div className="two-col">
                  <div className="general-module">
                    <div className="selectTrelloGrade">
                      <Select
                        styles={SelectGrade}
                        value={matterSelected}
                        onChange={handleChangeSubject}
                        options={subjects
                          .filter((course) => course.Level === grade)
                          .map((item) => ({
                            value: item.Subject,
                            label: getNameSubject(item.Subject, subjects),
                          }))
                          .sort((x, y) => {
                            let a = x.label.toUpperCase(),
                              b = y.label.toUpperCase();
                            return a == b ? 0 : a > b ? 1 : -1;
                          })}
                        placeholder={t("selectMatter")}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <table className="content-table-forum">
                <thead>
                  <tr>
                    <th>{t("forum")}</th>
                    <th>{t("author")}</th>
                    <th>{t("startDate")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {forums.map((item, i) => (
                    <tr key={i}>
                      <td>
                        <span className="table-forum--number">
                          {" "}
                          {t("forum")} {i + 1}:{" "}
                        </span>
                        <p className="table-forum--title">{item.Title}</p>
                        <p className="table-forum--description">
                          {item.Description}
                        </p>
                      </td>

                      <td>
                        <span className="table-forum--author">
                          {item.AuthorName}
                        </span>
                        <p className="list-library__aditional">
                          {getGradesOrdinals(item.LevelCode, getLanguage())} -{" "}
                          {getNameSubject(item.SubjectCode, subjects)}
                        </p>
                      </td>
                      <td>
                        <span className="table-forum--date">
                          {" "}
                          {item.CreatedAt}{" "}
                        </span>
                      </td>
                      <td>
                        <button
                          type="submit"
                          className="button-table-homework"
                          onClick={() =>
                            history.push(`/students/forum/${item.Id}`)
                          }
                        >
                          {t("enter")}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {!forums.length && !loading && (
                <EmptyListStyled>
                  <i className="fas fa-book-reader"></i> {t("empty")}
                </EmptyListStyled>
              )}
              {count > 1 && (
                <Pagination
                  count={count}
                  page={page}
                  onChange={handleChangePaginate}
                />
              )}
            </div>
          </div>
          {loading && <Loader />}
        </div>
      </div>
    </>
  );
};

export default Forum;
