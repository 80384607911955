import { getMessagesReceiveds } from "../../api/message";
import loading from "./loading";

export const getMetadataReceivedsSuccess = (metadata) => {
  return {
    type: "METADATA_RECEIVED_MESSAGES",
    payload: metadata,
  };
};

export const getMetadataReceivedsThunk = (page, pageSize) => {
  return async (dispatch, getState) => {
    try {
      dispatch(loading(true));
      const res = await getMessagesReceiveds(page, pageSize);
      const metadata = res.data;
      dispatch(getMetadataReceivedsSuccess(metadata));
    } catch {
    } finally {
      dispatch(loading(false));
    }
  };
};
