import React from "react";
import "./style.css";
import currentSubject from "../../../../redux/actions/subject";
import { getLessonsBySubjectsAndGradesThuk } from "../../../../redux/actions/lessons";
import { useDispatch, useSelector } from "react-redux";

const SubjectItem = ({ subject }) => {
  const dispatch = useDispatch();
  const item = useSelector((state) => state.subject);

  const hadleSubjectSelected = () => {
    dispatch(currentSubject(subject));
    dispatch(getLessonsBySubjectsAndGradesThuk(subject.Level, subject.Subject));
  };

  const imageLoad = require(`../../../../assets/images/${assignIcon(
    subject.Subject
  )}`);

  function assignIcon(subject) {
    switch (subject) {
      case "EN":
        return "LessonEnglish-icon.svg";
      case "SP":
        return "LessonSpanish-icon.svg";
      case "MAT-EN":
        return "LessonMath-icon.svg";
      case "SCI-EN":
        return "LessonScience-icon.svg";
      case "MAT-SP":
        return "LessonMath-icon.svg";
      case "SCI-SP":
        return "LessonScience-icon.svg";
      case "SCI-SO":
        return "LessonSocialStudies-icon.svg";
      case "EDF-SP":
        return "LessonEducacionFisica-icon.svg";
      case "ART-SP":
        return "LessonBellasArtes-icon.svg";
      case "SLD-SP":
        return "LessonSalud-icon.svg";
      default:
        return "LessonDefault-icon.svg";
    }
  }

  return (
    <>
      <div className="item-matter--five-col">
        <a onClick={hadleSubjectSelected}>
          <div className="item-matter">
            <div
              className={
                item.Level === subject.Level && item.Subject === subject.Subject
                  ? "item-active"
                  : ""
              }
            >
              <img src={imageLoad} />
              <p className="item-matter-title-lesson">{subject.SubjectName}</p>
            </div>
          </div>
        </a>
      </div>
    </>
  );
};

export default SubjectItem;
