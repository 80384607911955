import axios from "./interceptors";
import {
    PORT_API_LESSONS,
    PORT_API_LESSON_DETAIL,
    PORT_API_GET_LESSON_ASSIGNED_TEACHER,
    PORT_API_GET_HOMEWORK_ASSIGNED_TEACHER,
    PORT_API_COMPLETED_ASSIGNMENTS,
    PORT_API_DETAIL_COMPLETED_ASSIGNMENT,
    PORT_API_ADDITIONAL_MATERIAL,
    PORT_API_OFFLINE_MATERIAL,
    PORT_API_GET_ADDITIONAL_MATERIAL_BY_ID,
    PORT_API_GET_TRIES_SCHOOOL,
    PORT_API_GET_TRIES_HOMESCHOOLER,
    PORT_API_GET_TRIES_EXAMS_SCHOOOL,
    PORT_API_GET_SUBSCRIPTIONS_ACTIVE_PERIODS,
    PORT_API_GET_REPORTS_AVERAGE_PERIOD,
    PORT_API_GET_ACADEMICS_YEARS,
    PORT_API_GET_ACADEMICS_DETAILS_BY_YEAR,
    PORT_API_GET_REPORT_ACADEMICS_DETAILS_BY_YEAR
} from "../config/environment";
import { getLanguage, getUser } from "../utils/utils";

export const getLessonsBySubjectsAndGrades = (levelCode, subjectCode) => {
    const data = {
        Token: getUser().Token,
        LangCode: getLanguage(),
        LessonModelList: [{
            LevelCode: levelCode,
            SubjectCode: subjectCode,
        }, ],
    };
    return axios({
        method: "POST",
        url: `${PORT_API_LESSONS}/api/lessons/by-lesson-models/true`,
        data: JSON.stringify(data),
        headers: { "Content-Type": "application/json" },
    });
};

export const getLessonById = (id) => {
    return axios.get(`${PORT_API_LESSON_DETAIL}/api/lessons/${id}`);
};

export const getLessonByIdConcept = (id) => {
    return axios.get(`${PORT_API_LESSON_DETAIL}/api/lessons/${id}/concept`);
};

export const getLessonByIdDefinitions = (id) => {
    return axios.get(`${PORT_API_LESSON_DETAIL}/api/lessons/${id}/definitions`);
};

export const getLessonByIdExamples = (id) => {
    return axios.get(`${PORT_API_LESSON_DETAIL}/api/lessons/${id}/examples`);
};

export const getLessonByIdPractices = (id) => {
    return axios.get(`${PORT_API_LESSON_DETAIL}/api/lessons/${id}/practices`);
};

export const getLessonByIdExamsTriesHomeSchooler = (id) => {
    return axios.get(`${PORT_API_LESSON_DETAIL}/api/lessons/${id}/exams`);
};

export const getLessonByIdExamsTriesSchool = (id) => {
    return axios.get(`${PORT_API_GET_TRIES_EXAMS_SCHOOOL}/api/planning/student/lessons/${id}/exams`);
};

export const getTriesHomeSchooler = (id) => {
    return axios.get(`${PORT_API_GET_TRIES_HOMESCHOOLER}/api/pre_post/pre/completed/${id}`);
};

export const getTriesSchool = (id) => {
    return axios.get(`${PORT_API_GET_TRIES_SCHOOOL}/api/pre_post/pre/completed/${id}`);
};

export const getLessonAssignedByTeacher = (page, pageSize) => {
    page = page - 1;
    return axios.get(
        `${PORT_API_GET_LESSON_ASSIGNED_TEACHER}/api/planning/student/lessons/${page}/${pageSize}`
    );
};

/** Modified by: Pedro Ortiz on 01/21/2021
 * The endpoint now is a POST (before was a GET), and the filters
 * are sent as an object property instead of the route
 * **/
export const getHomeworkAssignedByTeacher = (page, pageSize) => {
    page = page - 1;
    const data = {
        FromPage: page,
        Limiter: pageSize
    };
    return axios({
        method: "POST",
        url: `${PORT_API_GET_HOMEWORK_ASSIGNED_TEACHER}/api/homework/pending/student/`,
        data: JSON.stringify(data),
        headers: { "Content-Type": "application/json" },
    });
};

/** Modified by: Pedro Ortiz on 01/21/2021
 * The endpoint now is a POST (before was a GET), and the filters
 * are sent as an object property instead of the route
 * **/
export const getCompletedAssignments = (grade, subject, page, pageSize) => {
    page = page - 1;
    const data = {
        FromPage: page,
        Limiter: pageSize,
        SubjectCode: subject,
        LevelCode: grade
    };
    return axios({
        method: "POST",
        url: `${PORT_API_COMPLETED_ASSIGNMENTS}/api/homework/completed/student/`,
        data: JSON.stringify(data),
        headers: { "Content-Type": "application/json" },
    });
};

export const getDetailCompletedAssignment = (userQuizResultId) => {
    return axios.get(
        `${PORT_API_DETAIL_COMPLETED_ASSIGNMENT}/api/homework/planning/for-student/quiz/${userQuizResultId}`
    );
};

export const getMaterialAdditionalByLesson = (lessonId, page, pageSize) => {
    page = page - 1;
    return axios.get(
        `${PORT_API_ADDITIONAL_MATERIAL}/api/additional-material/lesson/read/${lessonId}/${page}/${pageSize}`
    );
};

export const getMaterialOffline = (lessonId, quizId = 0, format = "pdf") => {
  /** Modified by: Pedro Ortiz on 01/20/2021
   * The endpoint is different from the original. After we clarified with Ovidio Hernández that
   * the offline lesson should be the same material that Athenas has, we change it to the
   * endpoint that is responsible of printing the lesson for students
   * **/
  return axios.get(
    `${PORT_API_OFFLINE_MATERIAL}/api/lessons/students/print/${lessonId}/${quizId}/${format}`
  );
};

export const getMaterialAdditionalById = (materialId) => {
    return axios.get(
        `${PORT_API_GET_ADDITIONAL_MATERIAL_BY_ID}/api/additional-material/read/${materialId}`
    );
};

export const getSubscriptionsActivePeriods = () => {
    return axios.get(
        `${PORT_API_GET_SUBSCRIPTIONS_ACTIVE_PERIODS}/api/subscriptions/active/periods/user/`
    );
};

export const getReportAverageByPeriod = (periodId, subjectCode, levelCode) => {
    if (subjectCode && levelCode) {
        return axios.get(
            `${PORT_API_GET_REPORTS_AVERAGE_PERIOD}/api/reports/average/period/${periodId}?subjectCode=${subjectCode}&levelCode=${levelCode}&includeDetails=true`
        );
    }
    return axios.get(
        `${PORT_API_GET_REPORTS_AVERAGE_PERIOD}/api/reports/average/period/${periodId}`
    );
};

export const getAcademicYear = () => {
    return axios.get(
        `${PORT_API_GET_ACADEMICS_YEARS}/api/academics/user/years`
    );
}

export const getAcademicYearGrades = (academicYearId) => {
    return axios.get(
        `${PORT_API_GET_ACADEMICS_DETAILS_BY_YEAR}/grades-academic?academicYearId=${academicYearId}`
    );
}

export const getReportAcademicYearGrades = (academicYearId) => {
    return axios.get(
        `${PORT_API_GET_REPORT_ACADEMICS_DETAILS_BY_YEAR}/grades-academic-pdf?academicYearId=${academicYearId}`
    );
}