import React, { useEffect, useState } from "react";
import "./style.css";
import {
  homeworkAuthorizationCode,
  getQuizHomework,
} from "../../../api/homework";
import { useParams } from "react-router-dom";
import { Loader } from "../../common/styled-components/loader";
import HomeworkMenu from "./homework-menu";
import Selection from "./selection";
import Pairing from "./pairing";
import TrueOrFalse from "./true-or-false";
import OpenQuestions from "./open-questions";

const Homework = () => {
  let { teacherPlanQuizId } = useParams();
  const [quiz, setQuiz] = useState(null);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const resCode = await homeworkAuthorizationCode(teacherPlanQuizId);
        const code = resCode.data.AuthorizationCode;
        setCode(code);
        const resQuiz = await getQuizHomework(code);
        const data = resQuiz.data;
        setQuiz(data);
      } catch {
        
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <HomeworkMenu />
      {quiz && quiz.QuizModel.QuizTypeId === "1" && (
        <Selection quiz={quiz} code={code} />
      )}
      {quiz && quiz.QuizModel.QuizTypeId === "2" && (
        <Pairing quiz={quiz} code={code} />
      )}
      {quiz && quiz.QuizModel.QuizTypeId === "3" && (
        <OpenQuestions quiz={quiz} code={code} />
      )}
      {quiz && quiz.QuizModel.QuizTypeId === "4" && (
        <TrueOrFalse quiz={quiz} code={code} />
      )}      
      {loading && <Loader />}
    </>
  );
};

export default Homework;
