import React from "react";
import "./style.css";

const ImageAttachment = ({ item }) => {
  const fileUrl = item.AttachmentUrl.split("/");
  const fileName = fileUrl[fileUrl.length - 1];
  const fileExt = getFileExtension(fileName);
  const imageLoad = require(`../../../../assets/images/${assignImage(
    fileExt
  )}`);

  function assignImage(ext) {
    switch (ext) {
      case "csv":
        return "icon-file-csv.jpg";
      case "doc":
        return "icon-file-doc.jpg";
      case "docx":
        return "icon-file-doc.jpg";
      case "jpg":
        return "icon-file-jpg.jpg";
      case "jpge":
        return "icon-file-jpg.jpg";
      case "png":
        return "icon-file-jpg.jpg";
      case "mp3":
        return "icon-file-mp3.jpg";
      case "mp4":
        return "icon-file-mp3.jpg";
      case "pdf":
        return "icon-file-pdf.jpg";
      case "xlm":
        return "icon-file-xlm.jpg";
      case "xlsx":
        return "icon-file-xlm.jpg";
      case "xlsm":
        return "icon-file-xlm.jpg";
      default:
        return "icon-file-jpg.jpg";
    }
  }

  function getFileExtension(filename) {
    return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined;
  }

  return (
    <>
      <div className="preview-docs-message--seven-col">
        {fileExt === "jpg" || fileExt === "png" ? (
          <img src={item.AttachmentUrl} alt="" />
        ) : (
          <img src={imageLoad} alt="" />
        )}
        <p>
          <a href={item.AttachmentUrl} target="_blank" download>
            {fileName}
          </a>
        </p>
      </div>
    </>
  );
};

export default ImageAttachment;
