import axios from "./interceptors";
import { PORT_API_GET_LIBRARY } from "../config/environment";

export const getLibraries = (data) => {
  return axios({
    method: "POST",
    url: `${PORT_API_GET_LIBRARY}/api/additional-material/read/`,
    data: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  });
};
