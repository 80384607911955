import React, { useEffect, useState } from "react";
import "./style.css";
import { getQuizById, getQuizAvailableById } from "../../../../../api/quiz";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Loader } from "../../../../common/styled-components/loader";
import QuizMenu from "./quiz-menu";
import Pairing from "./pairing";
import prevPath from "../../../../../redux/actions/path";
import Selection from "./selection";
import { useSelector } from "react-redux";


const Quiz = () => {
  let { quizId } = useParams();
  const history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();
  const [quiz, setQuiz] = useState(null);
  const [loading, setLoading] = useState(false);
  const { subscriptionType } = useSelector((state) => ({
    subscriptionType: state.subscriptionType
  }));

  useEffect(() => {
    getQuiz(quizId);
    dispatch(prevPath(location.pathname));
  }, []);

  const getQuiz = async (quizId, response) => {
    setLoading(true);
    try {
      const res = await getQuizById(quizId, response);
      const data = res.data.QuizRequestModel;
      if (data && data.QuizModel.IsExam === "1") {
        if (subscriptionType !== "H") {
          const available = await getQuizAvailableById(quizId);
          if (!available.data) {
            history.push(
              `/students/lesson/${data.QuizModel.LessonId}?tab=challenge`
            );
          }
        }
      }
      setQuiz(data);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const getResponseQuiz = (isExam) => {
    if (isExam === "0") {
      const response = 1;
      getQuiz(quizId, response);
    }
  };

  return (
    <>
      <QuizMenu quiz={quiz ? quiz.QuizModel : undefined} />
      {quiz && quiz.QuizModel.QuizTypeId === "1" && <Selection quiz={quiz} subject={quiz.SubjectCode} level={quiz.LevelCode} getResponseQuiz={getResponseQuiz} />}
      {quiz && quiz.QuizModel.QuizTypeId === "2" && <Pairing quiz={quiz} subject={quiz.SubjectCode} level={quiz.LevelCode} />}
      {loading && <Loader />}
    </>
  );
};

export default Quiz;
