import { getSubjectsAndCourses } from "../../api/subject";

export const getSubjestsSuccess = (subjects) => {
  return {
    type: "GET_SUBJECTS_SUCCESS",
    payload: subjects,
  };
};

export const subscriptionType = (type) => {
  return {
    type: "SUBSCRIPTION_TYPE",
    payload: type,
  };
};

export const getSubjectsAndCoursesThuk = () => {
  return async (dispatch, getState) => {
    try {
      const res = await getSubjectsAndCourses();
      const courses = res.data.UserSubscribedLevelsSubjectsModelList;
      const type = res.data.SubscriptionType;
      dispatch(getSubjestsSuccess(courses));
      dispatch(subscriptionType(type));
    } catch {}
  };
};
