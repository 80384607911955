import React, { useEffect } from "react";
import "./style.css";
import HeaderLogin from "./header/index";
import FormLogin from "./form/index";
import FooterLogin from "./footer/index";
import { Loader } from "../common/styled-components/loader";
import { useSelector } from "react-redux";
import { isAuthenticated, getUser } from "../../utils/utils";
import { useHistory } from "react-router-dom";

const Login = () => {
  const history = useHistory();

  const { loading } = useSelector((state) => ({
    loading: state.loading,
  }));

  useEffect(() => {
    if (isAuthenticated()) {
      history.push("/students/home");
    }
  }, []);

  return (
    <>
      <HeaderLogin />
      <FormLogin />
      <FooterLogin />
      {loading && <Loader />}
    </>
  );
};

export default Login;
