import { getLessonsBySubjectsAndGrades } from "../../api/lesson";
import loading from "./loading";

export const getLessonsSuccess = (lessons) => {
  return {
    type: "GET_LESSONS_SUCCESS",
    payload: lessons,
  };
};

export const getLessonsBySubjectsAndGradesThuk = (levelCode, subjectCode) => {
  return async (dispatch, getState) => {
    try {
      dispatch(loading(true));
      const res = await getLessonsBySubjectsAndGrades(levelCode, subjectCode);
      const lessons = res.data.LessonModelList;
      dispatch(getLessonsSuccess(lessons));
    } catch {
    } finally {
      dispatch(loading(false));
    }
  };
};
